<template>
  <form @submit.prevent="onSubmit">
    <div class="p-2">
      <div class="flex justify-space-between">
        <label>
          {{ $t('D_ASSISTANCE.SETTINGS.BOT_NAME_LABEL') }}
          <input
            v-model.trim="botName"
            type="text"
            :disabled="!hasEditPermission('ChatGPT')"
          />
        </label>
        <label>
          {{ $t('D_ASSISTANCE.SETTINGS.ENGINE_LABEL') }}
          <input
            :disabled="true"
            v-model.trim="engine"
            type="text"
          />
        </label>
      </div>
        <label for="free-text">{{ $t('D_ASSISTANCE.SETTINGS.FLAGS') }}</label>
        <span style="white-space: pre-wrap;" class="mb-1 text-sm">{{ $t('D_ASSISTANCE.SETTINGS.FLAGS_FIXED_TEXT') }}</span>
      </div>
      <div>
        <label for="free-text">{{ $t('D_ASSISTANCE.SETTINGS.FREE_TEXT_LABEL') }}</label>
        <textarea id="free-text" v-model="freeText" :disabled="!hasEditPermission('ChatGPT')"></textarea>
        <div class="text-xs text-slate-400" style="margin-top: -1rem;margin-bottom: 1rem;">{{$t('D_ASSISTANCE.SETTINGS.MAX_CHARACTERS_HINT')}}</div>
      </div>
      <section v-if="urls.length">
        <label>
          {{ $t('D_ASSISTANCE.SETTINGS.URLS_LABEL') }}
        </label>
        <div
          class="w-full w-full p-4 bg-slate-25 dark:bg-slate-700 rounded-lg border border-solid border-slate-50 dark:border-slate-700 mb-4"
        >
          <div class="flex" v-for="(url, index) in urls" :key="index">
            <input :id="'url-' + index" v-model="urls[index]" type="text">
            <woot-button
              v-if="hasEditPermission('ChatGPT')"
              icon="dismiss"
              variant="clear"
              color-scheme="secondary"
              @click="removeUrl(index)"
            />
          </div>

          <woot-button class="mt-4"
            v-if="isAddUrlAvailable"
            icon="add"
            color-scheme="success"
            variant="smooth"
            size="small"
            @click.prevent="addUrl"
          >
            {{ $t('D_ASSISTANCE.SETTINGS.ADD_URL_BUTTON_LABEL') }}
          </woot-button>

        </div>
      </section>
      <section v-if="attachedFiles.length">
        <label>
          {{ $t('D_ASSISTANCE.SETTINGS.FILES_LABEL') }}
        </label>
        <div
          class="w-full w-full p-4 bg-slate-25 dark:bg-slate-700 rounded-lg border border-solid border-slate-50 dark:border-slate-700 mb-4"
        >
          <div v-if="attachedFiles.length" class="attachment-preview-box">
            <attachment-preview
              :attachments="attachedFiles"
              :remove-attachment="removeAttachment"
              :readonly="!hasEditPermission('ChatGPT')"
            />
          </div>
          <file-upload
                       v-if="isAddFileAvailable"
                       ref="upload"
                       :multiple="true"
                       :drop="false"
                       :drop-directory="false"
                       @input-file="onIndirectFileUpload"
          >
            <woot-button class="mt-4"
              icon="add"
              color-scheme="success"
              variant="smooth"
              size="small"
            >
              {{ $t('D_ASSISTANCE.SETTINGS.ADD_FILE_BUTTON_LABEL') }}
            </woot-button>
          </file-upload>
        </div>
      </section>
      <div class="w-full">
      <div class="flex flex-row justify-start">
          <woot-button
            v-if="!urls.length && hasEditPermission('ChatGPT')"
            class-names="mr-2"
            icon="add"
            color-scheme="success"
            variant="smooth"
            size="small"
            @click.prevent="addUrl"
          >
            {{ $t('D_ASSISTANCE.SETTINGS.ADD_URL_BUTTON_LABEL') }}
          </woot-button>
          <file-upload
            v-if="!attachedFiles.length && hasEditPermission('ChatGPT')"
            ref="upload2"
            v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
            :multiple="true"
            :drop="false"
            :drop-directory="false"
            @input-file="onIndirectFileUpload"
          >
            <woot-button
              v-if="!attachedFiles.length"
              icon="add"
              color-scheme="success"
              variant="smooth"
              size="small"
            >
              {{ $t('D_ASSISTANCE.SETTINGS.ADD_FILE_BUTTON_LABEL') }}
            </woot-button>
          </file-upload>
      </div>
      <div class="flex flex-row justify-end gap-2 py-2 px-0=">
        <woot-button variant="clear" @click.prevent="onCancel">
          {{ $t('MERGE_CONTACTS.FORM.CANCEL') }}
        </woot-button>
        <woot-button type="submit" :disabled="!isValidForm || !hasEditPermission('ChatGPT')" :is-loading="isSubmitting">
          {{ $t('PLATFORM.FORM.SUBMIT') }}
        </woot-button>
      </div>
    </div>
    <div v-if="isLoading" class="dd-loader">
      <spinner />
    </div>
  </form>
</template>

<script>

import alertMixin from "shared/mixins/alertMixin";
import OnlineWaAPI from 'dashboard/api/onlinewa'
import Spinner from 'shared/components/Spinner.vue';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import FileUpload from 'vue-upload-component';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import hasPermissionMixin from 'dashboard/mixins/hasPermission';

const MAXIMUM_FILE_UPLOAD_SIZE = 40;

export default {
  mixins: [alertMixin, hasPermissionMixin],
  components: {
    Spinner,
    AttachmentPreview,
    FileUpload,
  },
  props: {
  },
  data() {
    return {
      freeText: '',
      urls: [],
      attachedFiles: [],
      botName: undefined,
      engine: "GPT-4",
      isSubmitting: false,
      isLoading: false,
      maxUrls: 1,
      maxFiles: 1,
    }
  },
  validations: {
  },
  mounted() {
    this.load()
  },
  computed: {
    isValidForm() {
      return (
        this.freeText !== '' ||
        this.urls.some(url => url !== '') ||
        this.attachedFiles.length > 0
      );
    },
    isAddUrlAvailable() {
      return this.urls.length < this.maxUrls && this.hasEditPermission('ChatGPT')
    },
    isAddFileAvailable() {
      return this.attachedFiles.length < this.maxFiles && this.hasEditPermission('ChatGPT')
    },
  },
  methods: {
    onIndirectFileUpload(file) {
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.attachFile({ file });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    attachFile({ file }) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        if (this.attachedFiles.length < this.maxFiles) {
          this.attachedFiles.push({
            resource: file,
            thumb: reader.result,
          });
        }
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },

    addUrl() {
      this.urls.push('');
    },
    removeUrl(index) {
      this.urls.splice(index, 1);
    },

    onCancel() {
      this.$emit('cancel');
    },

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const payload = {
        free_text: this.freeText?.slice(0, 1000),
        urls_array: this.urls,
        files: this.attachedFiles?.filter(x => !!x.resource.file)?.map(x=>x.resource.file),
        bot_name: this.botName,
        keep: this.attachedFiles?.filter(x => !x.resource.file && x.key)?.map(x=>({key: x.key, type: 'file', source: x.resource?.name}))
      }

      this.isSubmitting = true;

      try {
        await OnlineWaAPI.sendSettingToAssistance(payload);
        this.showAlert(this.$t('D_ASSISTANCE.SETTINGS.API.SEND_SUCCESS'));
        this.onCancel();
      } catch (err) {
        console.error(err)
        this.showAlert(this.$t('PLATFORM.API.SAVE_ERROR'));
      } finally {
        this.isSubmitting = false;
      }
    },
    async load() {
      this.isLoading = true;
      try {
        const {data} = await OnlineWaAPI.getSettingToAssistance();
        const details = data?.details || []
        this.botName = data?.bot_name;
        this.maxFiles = data?.limits?.max_file_count;
        this.maxUrls = data?.limits?.max_url_count;
        this.freeText = details.find(x=>x.type === 'text')?.source;
        this.urls = details.filter(x=>x.type === 'url')?.map(x=>x.source) || [];
        this.attachedFiles = details.filter(x=>x.type === 'file')
          ?.map(x=>({
            key: x.key,
            resource: {
              file: undefined,
              name: x.source,
              type: [],
              size:null
            },
        })) || [];
      } catch (err) {
        console.error(err)
        this.showAlert(this.$t('PLATFORM.API.GET_ERROR'));
      } finally {
        this.isLoading = false;
      }
    },
  },

}
</script>

<style lang="scss" scoped>


</style>
