<template>
  <woot-modal size="modal-big" :show.sync="show" :on-close="onClose">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header :header-title="pageTitle" />
      <form class="flex flex-col w-full items-start" @submit.prevent="editRole">
        <!-- Role Name Input -->
        <div class="w-full">
          <label :class="{ error: $v.roleName.$error }">
            {{ $t('ROLES.TABLE.HEADER.NAME') }}
            <input
              v-model.trim="roleName"
              type="text"
              maxlength="15"
              :disabled="readOnly"
              @input="$v.roleName.$touch"
            />
          </label>
        </div>
        <div class="tabs w-full">
          <div
            class="tab"
            :class="{ active: activeTab === 'general' }"
            @click="activeTab = 'general'"
          >
            {{ $t('ROLES.TABS.GENERAL') }}
          </div>
          <div
            class="tab"
            :class="{ active: activeTab === 'inboxes' }"
            @click="activeTab = 'inboxes'"
          >
            {{ $t('ROLES.TABS.INBOX') }}
          </div>
        </div>
        <!-- GENERAL Permissions Table -->
        <div v-show="activeTab === 'general'" class="w-full table-container general">
          <table class="woot-table">
            <thead>
              <tr>
                <th class="thin">{{ '#' }}</th>
                <th>{{ $t('ROLES.TABLE.HEADER.PAGE') }}</th>
                <th class="text-center">{{ $t('ROLES.TABLE.HEADER.READ') }}</th>
                <th class="text-center">
                  {{ $t('ROLES.TABLE.HEADER.CREATE') }}
                </th>
                <th class="text-center">
                  {{ $t('ROLES.TABLE.HEADER.UPDATE') }}
                </th>
                <th class="text-center">
                  {{ $t('ROLES.TABLE.HEADER.DELETE') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(resource, index) in Object.keys(resources)"
                :key="resource"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <span
                    v-tooltip.top="{
                      content: permissionTooltip(resource),
                      container: '.table-container.general',
                    }"
                  >
                    {{ permissionName(resource) }}
                  </span>
                </td>
                <td
                  v-for="action in ['read', 'create', 'update', 'delete']"
                  :key="action"
                  class="p-0 align-middle"
                >
                  <label>
                    <input
                      v-if="general_permissions[resource][action] !== 'no'"
                      v-model="permission(resource)[action]"
                      type="checkbox"
                      :disabled="readOnly"
                      @click.stop
                    />
                    <input v-else type="checkbox" :disabled="true" />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- INBOX Permissions Table -->
        <div
          v-show="activeTab === 'inboxes' && inboxes.length"
          :class="`w-full table-container inboxes ${isRTLView ? 'is-rtl' : 'is-ltr'}`"
        >
          <table class="woot-table">
            <thead>
              <tr>
                <th class="sticky-col-header thin">{{ '#' }}</th>
                <th class="sticky-col-header min-w-150px">
                  {{ $t('ROLES.TABLE.HEADER.FEATURE') }}
                </th>
                <th
                  v-for="inbox in sortedInboxes"
                  :key="inbox.id"
                  class="text-center"
                >
                  {{ inbox.name }}
                </th>
                <th class="text-center">
                  {{ $t('ROLES.TABLE.HEADER.FUTURE_INBOX') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(inboxPermission, index) in editableInboxPermissions"
                :key="inboxPermission.name"
              >
                <td class="sticky-col">{{ index + 1 }}</td>
                <td class="sticky-col">
                  <span
                    v-tooltip.top="{
                      content: inboxPermissionTooltip(inboxPermission.name),
                      container: '.table-container.inboxes',
                    }"
                  >
                    {{ inboxPermissionName(inboxPermission.name) }}
                  </span>
                </td>
                <td
                  v-for="inbox in sortedInboxes"
                  :key="inbox.id"
                  class="p-0 align-middle"
                >
                  <label>
                    <input
                      v-model="inboxPermission.ids[inbox.id]"
                      type="checkbox"
                      :disabled="readOnly"
                      @input="checkInboxPermissions(inboxPermission, inbox.id)"
                    />
                  </label>
                </td>
                <td class="p-0 align-middle">
                  <label>
                    <input
                      v-model="inboxPermission.default"
                      type="checkbox"
                      :disabled="readOnly"
                      @input="checkInboxPermissions(inboxPermission)"
                    />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <woot-submit-button
            :disabled="$v.roleName.$invalid || uiFlags.isUpdating || name === 'General Manager'"
            :button-text="$t('ROLES.EDIT.SUBMIT')"
            :loading="uiFlags.isUpdating"
          />
          <button class="button clear" @click.prevent="onClose">
            {{ $t('ROLES.EDIT.CANCEL') }}
          </button>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
import rtlMixin from 'shared/mixins/rtlMixin';
import { GENERAL_PERMISSIONS, INBOX_PERMISSIONS } from './constants';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  mixins: [rtlMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    permissions: {
      type: Object,
      required: true,
    },
    inboxPermissions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      roleName: this.name,
      show: true,
      activeTab: 'general',
      resources: GENERAL_PERMISSIONS,
      editableInboxPermissions: [],
    };
  },
  computed: {
    pageTitle() {
      return `${this.$t('ROLES.EDIT.TITLE')} - ${this.name}`;
    },
    ...mapGetters({
      uiFlags: 'roles/getUIFlags',
      inboxes: 'inboxes/getInboxes',
    }),
    initialInboxPermissions() {
      return INBOX_PERMISSIONS.map(permission => {
        let existingPermission = this.inboxPermissions.find(
          p => p.name === permission
        );

        const ids = this.inboxes.reduce((acc, inbox) => {
          acc[inbox.id] = existingPermission
            ? existingPermission.ids[inbox.id] || false
            : false;
          return acc;
        }, {});

        return existingPermission
          ? { ...existingPermission, ids }
          : { name: permission, ids, default: false };
      });
    },
    general_permissions() {
      return GENERAL_PERMISSIONS;
    },
    sortedInboxes() {
      return this.inboxes.slice().sort((a, b) => a.name.localeCompare(b.name));
    },
    readOnly() {
      return this.name === 'General Manager';
    },
  },
  validations: {
    roleName: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(15),
    },
  },
  mounted() {
    this.$store.dispatch('inboxes/get');
    this.$store.dispatch('roles/get');
    this.editableInboxPermissions = this.initialInboxPermissions;
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async editRole() {
      try {
        await this.$store.dispatch('roles/update', {
          id: this.id,
          name: this.roleName,
          permissions: this.permissions,
          inbox_permissions: this.editableInboxPermissions,
        });
        this.showAlert(this.$t('ROLES.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(error.message);
      }
    },
    permission(resource) {
      if (!this.permissions[resource]) {
        this.permissions[resource] = GENERAL_PERMISSIONS[resource];
      }
      return this.permissions[resource];
    },
    permissionName(resource) {
      return this.$t(
        `ROLES.GENERAL_PERMISSIONS.${resource.replace(/ /g, '_').toUpperCase()}`
      );
    },
    permissionTooltip(resource) {
      return this.$t(
        `ROLES.GENERAL_PERMISSIONS.${resource
          .replace(/ /g, '_')
          .toUpperCase()}_TOOLTIP`
      );
    },
    inboxPermissionName(resource) {
      return this.$t(
        `ROLES.INBOX_PERMISSIONS.${resource.replace(/ /g, '_').toUpperCase()}`
      );
    },
    inboxPermissionTooltip(resource) {
      return this.$t(
        `ROLES.INBOX_PERMISSIONS.${resource
          .replace(/ /g, '_')
          .toUpperCase()}_TOOLTIP`
      );
    },
    checkInboxPermissions(permission, inboxId = null) {
      setTimeout(() => {
        const val = inboxId ? permission.ids[inboxId] : permission.default;
        if (val) {
          this.setReadInboxPermission(inboxId);
          this.checkReplyInboxPermission(inboxId);
        } else {
          if (permission.name === INBOX_PERMISSIONS[0]) {
            this.unSetInboxPermissions(inboxId, 'all');
          }
          if (permission.name === 'Inbox reply') {
            this.unSetInboxPermissions(inboxId, 'reply');
          }
        }
      }, 100);
    },
    setReadInboxPermission(inboxId) {
      const readInboxPerm = this.editableInboxPermissions.find(
        perm => perm.name === INBOX_PERMISSIONS[0]
      );
      if (inboxId) {
        this.$set(readInboxPerm.ids, inboxId, true);
      } else {
        readInboxPerm.default = true;
      }
    },
    checkReplyInboxPermission(inboxId) {
      const relatedNames = [
        'Inbox private notes',
        'Inbox attach files',
        'Inbox delay message',
      ];
      const anyRelatedPerm = this.editableInboxPermissions.some(
        perm =>
          relatedNames.includes(perm.name) &&
          (inboxId ? perm.ids[inboxId] : perm.default)
      );
      if (anyRelatedPerm) {
        const replyPerm = this.editableInboxPermissions.find(
          perm => perm.name === 'Inbox reply'
        );
        if (inboxId) {
          this.$set(replyPerm.ids, inboxId, true);
        } else {
          replyPerm.default = true;
        }
      }
    },
    unSetInboxPermissions(inboxId, type) {
      const replyRelatedNames = [
        'Inbox private notes',
        'Inbox attach files',
        'Inbox delay message',
      ];
      this.editableInboxPermissions.forEach(perm => {
        if (type !== 'reply' || replyRelatedNames.includes(perm.name)) {
          if (inboxId) {
            this.$set(perm.ids, inboxId, false);
          } else {
            perm.default = false;
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.tabs {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  margin-bottom: 1rem;
}

.tab {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
}

.tab.active {
  border-color: #5061e3; /* Chatwoot's primary color */
  font-weight: 600;
}

/* Container to manage the scrolling */
.table-container {
  overflow-x: auto;
  margin-bottom: 2rem;
  max-height: 500px;
}

/* General table styles */
.woot-table {
  min-width: 600px; /* Ensures table is scrollable on smaller screens */
  border-collapse: collapse;
}

.woot-table th,
.woot-table td {
  text-align: auto;
  padding: 0.75rem;
  border: 1px solid #eee;
  transition: background-color 0.3s ease;
}

/* Header styling */
.woot-table th {
  background-color: #f8f8f8;
  position: sticky;
  top: 0;
  z-index: 2;
  min-width: 112px;
  &.min-w-150px {
    min-width: 150px;
  }
  &.thin {
    min-width: unset;
  }
}

.woot-table td {
  &.p-0 {
    padding: 0;
  }
  > label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0.75rem;
    cursor: pointer;
  }
  &.align-middle input {
    margin: 0 auto;
  }
}

/* Zebra striping for rows */
.woot-table tr:nth-child(odd) td {
  background-color: #f9f9f9;
}

/* Checkbox styling */
input[type='checkbox'] {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ddd;
  outline: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &.blocked {
    pointer-events: none; /* Prevent interaction but keep visual styles */
  }
}

input[type='checkbox']:checked {
  background-color: #5061e3; /* Chatwoot's primary color */
  border-color: #5061e3; /* Chatwoot's primary color */
}
/* Base styles for sticky behavior */
.woot-table th.sticky-col-header {
  background-color: #f8f8f8;
  position: sticky;
  z-index: 4;
}

.woot-table td.sticky-col {
  position: sticky;
  background-color: white;
  z-index: 3;
}

/* LTR styles */
.is-ltr .woot-table th.sticky-col-header:first-child {
  left: 0;
}

.is-ltr .woot-table th.sticky-col-header:nth-child(2) {
  left: 40px;
}

.is-ltr .woot-table td.sticky-col:first-child {
  left: 0;
}

.is-ltr .woot-table td.sticky-col:nth-child(2) {
  left: 40px;
}

/* RTL styles */
.is-rtl .woot-table th.sticky-col-header:first-child {
  right: 0;
}

.is-rtl .woot-table th.sticky-col-header:nth-child(2) {
  right: 40px;
}

.is-rtl .woot-table td.sticky-col:first-child {
  right: 0;
}

.is-rtl .woot-table td.sticky-col:nth-child(2) {
  right: 40px;
}
</style>
