<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      v-if="hasCreatePermission('Roles')"
      color-scheme="success"
      :class-names="`button--fixed-top`"
      icon="add-circle"
      @click="openAddPopup()"
    >
      {{ $t('ROLES.HEADER_BTN_TXT') }}
    </woot-button>

    <div class="flex flex-row gap-4">
      <div class="">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('ROLES.LOADING')"
        />
        <div v-else>
          <p v-if="!rolesList.length">
            {{ $t('ROLES.404') }}
          </p>
          <table v-else class="woot-table">
            <thead>
              <tr>
                <th>{{ $t('ROLES.TABLE.HEADER.NAME') }}</th>
                <th>{{ $t('ROLES.TABLE.HEADER.CREATED_BY') }}</th>
                <th>{{ $t('ROLES.TABLE.HEADER.CREATED_AT') }}</th>
                <th>{{ $t('ROLES.TABLE.HEADER.CHANGED_BY') }}</th>
                <th>{{ $t('ROLES.TABLE.HEADER.CHANGED_AT') }}</th>
                <th>{{ $t('ROLES.TABLE.HEADER.ACTIONS') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(role, index) in rolesList" :key="role.id">
                <td>
                  <span class="agent-name">
                    {{ role.name }}
                  </span>
                </td>
                <td>
                  <span class="agent-name">
                    {{ role.created_by }}
                  </span>
                </td>
                <td>
                  <span class="agent-name">
                    {{ messageStamp(role.created_at, 'HH:mm &nbsp; dd/MM/yyyy') }}
                  </span>
                </td>
                <td>
                  <span class="agent-name">
                    {{ role.updated_by }}
                  </span>
                </td>
                <td>
                  <span class="agent-name">
                    {{ messageStamp(role.updated_at, 'HH:mm &nbsp; dd/MM/yyyy') }}
                  </span>
                </td>
                <!-- Actions -->
                <td>
                  <div class="button-wrapper">
                    <woot-button
                      v-if="hasEditPermission('Roles')"
                      v-tooltip.top="$t('ROLES.EDIT.BUTTON_TEXT')"
                      variant="smooth"
                      size="tiny"
                      color-scheme="secondary"
                      icon="edit"
                      class-names="grey-btn"
                      @click="openEditPopup(role)"
                    />
                    <woot-button
                      v-if="hasDeletePermission('Roles')"
                      v-tooltip.top="$t('ROLES.DELETE.BUTTON_TEXT')"
                      :disabled="role.name === 'General Manager'"
                      variant="smooth"
                      color-scheme="alert"
                      size="tiny"
                      icon="dismiss-circle"
                      class-names="grey-btn"
                      :is-loading="loading[role.id]"
                      @click="openDeletePopup(role, index)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-role :on-close="hideAddPopup" />
    </woot-modal>
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-role
        v-if="showEditPopup"
        :id="currentRole.id"
        :name="currentRole.name"
        :permissions="currentRole.permissions"
        :inbox-permissions="currentRole.inbox_permissions"
        :on-close="hideEditPopup"
      />
    </woot-modal>
    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('ROLES.DELETE.CONFIRM.TITLE')"
      :message="$t('ROLES.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="$t('ROLES.DELETE.CONFIRM.YES')"
      :reject-text="$t('ROLES.DELETE.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import AddRole from './AddRole.vue';
import EditRole from './EditRole.vue';
import timeMixin from 'dashboard/mixins/time';
import hasPermissionMixin from 'dashboard/mixins/hasPermission';

export default {
  components: {
    AddRole,
    EditRole,
  },
  mixins: [globalConfigMixin, timeMixin, hasPermissionMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeletePopup: false,
      showEditPopup: false,
      currentRole: {},
    };
  },
  computed: {
    ...mapGetters({
      rolesList: 'roles/getRoles',
      uiFlags: 'roles/getUIFlags',
      currentUserId: 'getCurrentUserID',
      globalConfig: 'globalConfig/get',
    }),
    deleteMessage() {
      return ` ${this.currentRole.name}?`;
    },
  },
  mounted() {
    this.$store.dispatch('roles/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    openEditPopup(role) {
      this.showEditPopup = true;
      this.currentRole = role;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },

    openDeletePopup(role) {
      this.showDeletePopup = true;
      this.currentRole = role;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentRole.id] = true;
      this.closeDeletePopup();
      this.deleteRole(this.currentRole.id);
    },
    async deleteRole(id) {
      try {
        await this.$store.dispatch('roles/delete', id);
        this.showAlert(this.$t('ROLES.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(error.message);
      } finally {
        this.loading[this.currentRole.id] = false;
        this.currentRole = {};
      }
    },
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
  },
};
</script>
