<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header :header-title="pageTitle" />
      <form class="w-full" @submit.prevent="editAgent()">
        <div class="w-full">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.EDIT.FORM.NAME.LABEL') }}
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.EDIT.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>

        <div class="w-full">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType">
              <option
                v-for="role in fullRoles"
                :key="role.name"
                :value="role.name"
              >
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
        </div>
        <label v-if="agentType === 'agent'">
          <input v-model="agentSeeAllConversations" type="checkbox" />
          {{ $t('AGENT_MGMT.EDIT.FORM.SEE_ALL_CONVERSATIONS.LABEL') }}
        </label>

        <div class="w-full">
          <label :class="{ error: $v.agentAvailability.$error }">
            {{ $t('PROFILE_SETTINGS.FORM.AVAILABILITY.LABEL') }}
            <select v-model="agentAvailability">
              <option
                v-for="role in availabilityStatuses"
                :key="role.value"
                :value="role.value"
              >
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentAvailability.$error" class="message">
              {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_AVAILABILITY.ERROR') }}
            </span>
          </label>
        </div>

        <div class="w-full mb-4 relative">
          <label>
            {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_SKILLS.LABEL') }}
            <custom-attribute-selector
              attribute-type="agent_attribute"
              :init-custom-attributes="customAttributes"
              @addAttribute="addAttribute"
            />
            <custom-attributes
              attribute-type="agent_attribute"
              attribute-class="conversation--attribute"
              :init-custom-attributes="customAttributes"
              class="even"
              @changeAttributes="changeAttributes"
            />
          </label>
        </div>
        <div class="w-full">
          <label :class="{ error: $v.agentTwoFactorType.$error }">
            2FA
            <select v-model="agentTwoFactorType" class="mb-2">
              <option value="" :disabled="isMandatory2fa">
                {{ $t(`AGENT_MGMT.EDIT.FORM.AGENT_TWO_FACTOR_AUTH.OFF`) }}
              </option>
              <option v-for="type in ['sms', 'email']" :key="type" :value="type">
                {{ $t(`AGENT_MGMT.EDIT.FORM.AGENT_TWO_FACTOR_AUTH.${type}`) }}
              </option>
            </select>
          </label>
        </div>
        <div v-show="agentTwoFactorType" class="py-3 px-4 bg-slate-25 w-full">
          <div v-show="agentTwoFactorType === 'sms'">
            <div v-if="!$v.agentTwoFactorType.emptyPhone" class="error text-center">
              <span class="message">
                {{ $t('AGENT_MGMT.EDIT.FORM.AUTH_PHONE.ERROR.EMPTY_PHONE') }}
              </span>
            </div>
            <div class="w-full">
              <label :class="{ error: $v.agentPhone1.$error }">
                {{ $t('AGENT_MGMT.EDIT.FORM.PHONE.LABEL_PHONE1') }}
                <input
                  v-model="agentPhone1"
                  type="text"
                  @input="$v.agentPhone1.$touch"
                  :placeholder="$t('AGENT_MGMT.EDIT.FORM.PHONE.PLACEHOLDER')"
                />
                <span v-if="$v.agentPhone1.$error" class="message">
                  {{ $t('AGENT_MGMT.EDIT.FORM.AUTH_PHONE.ERROR.FORMAT') }}
                </span>
              </label>
            </div>
          </div>
          <div v-show="agentTwoFactorType === 'email'" class="w-full">
            <div v-if="!$v.agentTwoFactorType.emptyEmail" class="error text-center">
              <span class="message">
                {{ $t('AGENT_MGMT.EDIT.FORM.AUTH_PHONE.ERROR.EMPTY_EMAIL') }}
              </span>
            </div>
            <div class="w-full">
              <label :class="{ error: $v.agentEmail1.$error }">
                {{ $t('AGENT_MGMT.EDIT.FORM.EMAIL.2FA') }}
                <input
                  v-model="agentEmail1"
                  type="email"
                  @input="$v.agentEmail1.$touch"
                  :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
                />
              </label>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <div class="w-[50%]">
            <woot-submit-button
              :disabled="
                $v.agentType.$invalid ||
                $v.agentName.$invalid ||
                $v.agentTwoFactorType.$invalid ||
                $v.agentPhone1.$invalid ||
                uiFlags.isUpdating
              "
              :button-text="$t('AGENT_MGMT.EDIT.FORM.SUBMIT')"
              :loading="uiFlags.isUpdating"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('AGENT_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
          <div class="w-[50%] text-right">
            <woot-button
              icon="lock-closed"
              variant="clear"
              @click.prevent="resetPassword"
            >
              {{ $t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_RESET_BUTTON') }}
            </woot-button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
import Auth from '../../../../api/auth';
import wootConstants from 'dashboard/constants/globals';
import { isPhoneIsraelEmpty } from 'shared/helpers/Validators';
import CustomAttributes from 'dashboard/routes/dashboard/conversation/customAttributes/CustomAttributes.vue';
import CustomAttributeSelector from 'dashboard/routes/dashboard/conversation/customAttributes/CustomAttributeSelector.vue';

const { AVAILABILITY_STATUS_KEYS } = wootConstants;

export default {
  components: {
    WootSubmitButton,
    Modal,
    CustomAttributes,
    CustomAttributeSelector,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    availability: {
      type: String,
      default: '',
    },
    seeAllConversations: {
      type: Boolean,
      default: undefined,
    },
    onClose: {
      type: Function,
      required: true,
    },
    twoFactorType: {
      type: String,
      default: '',
    },
    phone1: {
      type: String,
      default: '',
    },
    email1: {
      type: String,
      default: '',
    },
    agentCustomAttributes: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      roles: [
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
      ],
      agentName: this.name,
      agentAvailability: this.availability,
      agentType: this.type,
      agentSeeAllConversations: this.seeAllConversations,
      agentCredentials: {
        email: this.email,
      },
      show: true,
      agentPhone1: this.phone1,
      agentEmail1: this.email1,
      agentTwoFactorType: this.twoFactorType,
      customAttributes: this.agentCustomAttributes,
    };
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentType: {
      required,
    },
    agentAvailability: {
      required,
    },
    agentPhone1: {
      isPhoneIsraelEmpty,
    },
    agentEmail1: {
      required,
      email,
    },
    agentTwoFactorType: {
      emptyPhone: function (value) {
        return value !== 'sms' || !!this.agentPhone1;
      },
      emptyEmail: function (value) {
        return value !== 'email' || !!this.agentEmail1;
      },
    },
  },
  computed: {
    pageTitle() {
      return `${this.$t('AGENT_MGMT.EDIT.TITLE')} - ${this.name}`;
    },
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      getAccount: 'accounts/getAccount', // d99d
      accountId: 'getCurrentAccountId', // d99d
      rolesFromStore: 'roles/getRoles',
    }),
    // d99d
    account() {
      return this.getAccount(this.accountId);
    },
    isMandatory2fa() {
      return this.account?.mandatory_2fa;
    },
    newRulesSystemEnabled() {
      return this.account?.features?.new_rules_system;
    },
    fullRoles() {
      if (!this.newRulesSystemEnabled) {
        return this.roles;
      }
      return this.rolesFromStore.map(role => ({
        name: role.name,
        label: role.name,
      }));
    },
    // .
    availabilityStatuses() {
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST').map(
        (statusLabel, index) => ({
          label: statusLabel,
          value: AVAILABILITY_STATUS_KEYS[index],
          disabled:
            this.currentUserAvailability === AVAILABILITY_STATUS_KEYS[index],
        })
      );
    },
  },
  mounted() {
    if (this.newRulesSystemEnabled) {
      this.$store.dispatch('roles/get');
    }
  },
  watch: {
    agentTwoFactorType(newVal) {
      if (newVal === 'email' && !this.agentEmail1) {
        this.agentEmail1 = this.email;
      }
    },
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async editAgent() {
      if (this.agentType === 'administrator') {
        this.agentSeeAllConversations = undefined;
      }
      try {
        await this.$store.dispatch('agents/update', {
          id: this.id,
          name: this.agentName,
          role: this.agentType,
          see_all_conversations: this.agentSeeAllConversations,
          availability: this.agentAvailability,
          custom_attributes: this.customAttributes,
          two_factor_type: this.agentTwoFactorType,
          phone1: this.agentPhone1, // d99d
          email1: this.agentEmail1, // d99d
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async resetPassword() {
      try {
        await Auth.resetPassword(this.agentCredentials);
        this.showAlert(
          this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ERROR_MESSAGE'));
      }
    },
    addAttribute(attributeKey, attributeValue) {
      this.$set(this.customAttributes, attributeKey, attributeValue);
    },
    changeAttributes(customAttrs) {
      this.customAttributes = customAttrs;
    },
  },
};
</script>
