import { frontendURL } from '../../../helper/URLHelper';
import account from './account/account.routes';
import agent from './agents/agent.routes';
import role from './roles/roles.routes';
import agentBot from './agentBots/agentBot.routes';
import attributes from './attributes/attributes.routes';
import automation from './automation/automation.routes';
import auditlogs from './auditlogs/audit.routes';
import billing from './billing/billing.routes';
import campaigns from './campaigns/campaigns.routes';
import canned from './canned/canned.routes';
import inbox from './inbox/inbox.routes';
import integrationapps from './integrationapps/integrations.routes';
import integrations from './integrations/integrations.routes';
import labels from './labels/labels.routes';
import macros from './macros/macros.routes';
import profile from './profile/profile.routes';
import reports from './reports/reports.routes';
import store from '../../../store';
import sla from './sla/sla.routes';
import onlinewaSettings from './onlinewasettings/onlinewasettings.routes';
import teams from './teams/teams.routes';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings'),
      name: 'settings_home',
      roles: ['administrator', 'agent'],
      redirect: () => {
        if (store.getters.getCurrentRole === 'administrator') {
          let settingsPath = 'accounts/:accountId/settings/general';
          if (store.getters.getCurrentFeatures?.general_settings_index === false) { // d99d
            settingsPath = 'accounts/:accountId/settings/agents';
          }
          if (store.getters.getCurrentFeatures?.agent_list === false) { // d99d
            settingsPath = 'accounts/:accountId/settings/teams';
          }
          return frontendURL(settingsPath);
        }
        const permissions = store.getters.getInboxPermissions;
        const routeMap = {
          'Custom attributes': 'custom-attributes',
          'Audit Logs': 'audit-log',
          Inbox: 'inboxes',
          Teams: 'teams',
          'Account settings': 'general',
          Roles: 'roles',
          Agents: 'agents',
          Automations: 'automation',
          Labels: 'labels',
          Macros: 'macros',
          'Canned Responses': 'canned-response',
          Sla: 'sla',
        };
        let route = 'general';
        for (let i = 0; i < permissions.length; i++) {
          let permission = permissions[i];
          if (routeMap.hasOwnProperty(permission.name) && permission.can_read) {
            route = routeMap[permission.name];
            break;
          }
        }
        return frontendURL(`accounts/:accountId/settings/${route}`);
      },
    },
    ...account.routes,
    ...agent.routes,
    ...role.routes,
    ...agentBot.routes,
    ...attributes.routes,
    ...automation.routes,
    ...auditlogs.routes,
    ...billing.routes,
    ...campaigns.routes,
    ...canned.routes,
    ...inbox.routes,
    //...integrationapps.routes,
    //...integrations.routes,
    ...labels.routes,
    ...macros.routes,
    ...profile.routes,
    ...reports.routes,
    ...sla.routes,
    ...teams.routes,
    ...onlinewaSettings.routes,
  ],
};
