<template>
  <div class="contact-info--wrapper">
    <div class="container">
      <div class="contact--group">
        <i class="ion ion-android-person" style="font-size: x-large" />
        <div class="meta">
          <p
            class="overflow-hidden whitespace-nowrap text-ellipsis margin-bottom-0"
          >
            {{ name }}
          </p>
        </div>
      </div>
      <div class="contact--group">
        <fluent-icon icon="call" class="file--icon" size="18" />
        <div class="meta">
          <p
            class="overflow-hidden whitespace-nowrap text-ellipsis margin-bottom-0"
          >
            {{ phoneNumber }}
          </p>
        </div>
      </div>
      <div class="contact--group">
        <fluent-icon icon="mail" class="file--icon" size="18" />
        <div class="meta">
          <p
            class="overflow-hidden whitespace-nowrap text-ellipsis margin-bottom-0"
          >
            {{ email }}
          </p>
        </div>
      </div>
    </div>
    <button class="send_button">Send Message</button>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    name: {
      type: String,
      default: '',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
  },
  computed: {
    formattedPhoneNumber() {
      return this.phoneNumber.replace(/\s|-|[A-Za-z]/g, '');
    },
    rawPhoneNumber() {
      return this.phoneNumber.replace(/\D/g, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-info--wrapper {
  padding: 5px;
}
.container {
  border-bottom: 1px solid lightgray;
  padding-botton: 5px;
  margin-bottom: 5px;
}
.send_button {
  display: flex;
  padding: 8px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  background: #258DEE;
}
.contact--group {
  align-items: center;
  display: flex;
  margin-top: var(--space-smaller);

  .meta {
    flex: 1;
    margin-left: var(--space-small);
  }

  .link-wrap {
    margin-left: var(--space-small);
  }
}
</style>
<style lang="scss">
.wa {
  .send_button {
    background: #cdf2c7;
  }
}
.is-from-bot {
  .send_button {
    background: #8f3bde;
  }
}
</style>
