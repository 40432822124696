<template>
  <div v-if="isShown" class="reply--wrapper" :class="wrapClass">
    <div
      v-for="(config, idx) in configs"
      :key="idx"
      class="reply-body--wrapper"
    >
      <div class="reply-body">
        <div class="reply-content">
          <div class="button--wrapper">
            <button class="buttons-type-button">
              {{ config.text }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import { MESSAGE_TYPE } from 'shared/constants/messages';

export default {
  name: 'Buttons',
  mixins: [messageFormatterMixin],
  props: {
    configs: { type: Array, default: [] },
  },
  computed: {
    isShown() {
      return !!this.configs;
    },
    wrapClass() {
      return {
        'incoming-schema': this.isIncoming,
        'outgoing-schema': !this.isIncoming,
      };
    },
    isIncoming() {
      return this.data?.message_type === MESSAGE_TYPE.INCOMING;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';
div[dir='ltr'] .reply--wrapper {
  margin: 5px -8px 6px -10px;
}
div[dir='rtl'] .reply--wrapper {
  margin: 5px -10px 6px -8px;
}
.button--wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  button {
    border-color: #5d7592 !important;
  }
}
.reply-body--wrapper {
  background: none !important;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
}

div[dir='ltr'] .reply-body {
  padding: 0 12px 0 8px;
}

div[dir='rtl'] .reply-body {
  padding: 0 8px 0 12px;
}

.reply-body {
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-height: 42px;
  overflow: hidden;
}
.reply-content {
  flex-grow: 1;
  overflow: hidden;
}
.reply-text {
  display: -webkit-box;
  overflow: hidden;
  font-size: $font-size-mini;
  line-height: 20px;
  color: var(--quoted-message-text);
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: pre-wrap;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
}

.buttons-type-button {
  justify-content: center;
  width: 100%;
  background: #258dee;
  border-radius: 8px;
  display: flex;
  height: 34px;
  padding: 5px 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
</style>
<style lang="scss">
.wa {
  .buttons-type-button {
    background: #cdf2c7;
    color: #2563EB;
  }
}
.is-from-bot {
  .buttons-type-button {
    background: #8f3bde;
  }
}
</style>
