<template>
  <div class="flex actions--container relative items-center gap-2">
    <woot-button
      v-tooltip="$t('CONVERSATION.HEADER.MARK_AS_UNREAD')"
      :disabled="unreadCount > 0 || incomingCount === 0"
      class="hollow secondary actions--button"
      icon="ion-email-unread"
      variant="clear"
      @click="setUnread"
    />
    <div style="display: none">
    <woot-button
      v-if="!currentChat.muted"
      v-tooltip="$t('CONTACT_PANEL.MUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-mute"
      @click="mute"
    />
    <woot-button
      v-else
      v-tooltip.left="$t('CONTACT_PANEL.UNMUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-1"
      @click="unmute"
    />
    <woot-button
      v-if="hasInboxPermission('Inbox send Transcript')"
      v-tooltip="$t('CONTACT_PANEL.SEND_TRANSCRIPT')"
      variant="clear"
      color-scheme="secondary"
      icon="share"
      @click="toggleEmailActionsModal"
    />
    </div>
    <mode-action v-if="isActive"
      :conversation-id="currentChat.id"
      :status="currentChat.status"
    />
    <resolve-action
      :conversation-id="currentChat.id"
      :status="currentChat.status"
    />
    <woot-button
      v-if="
        hasInboxPermission('Inbox export conversations') ||
        isBafiConversationAssigner ||
        hasInboxPermission('Inbox send Transcript')
      "
      class="more--button"
      variant="clear"
      size="large"
      color-scheme="secondary"
      icon="ion-android-more-vertical"
      @click="toggleConversationActions"
    />
    <div
      v-if="showConversationActions"
      v-on-clickaway="hideConversationActions"
      class="dropdown-pane dropdowm--bottom"
      :class="{ 'dropdown-pane--open': showConversationActions }"
    >
      <woot-dropdown-menu>
        <woot-dropdown-item>
          <button
            v-if="hasInboxPermission('Inbox send Transcript')"
            class="button clear"
            @click="toggleEmailActionsModal"
          >
            {{ $t('CONTACT_PANEL.SEND_TRANSCRIPT') }}
          </button>
          <button
            v-if="hasInboxPermission('Inbox export conversations')"
            class="button clear"
            @click="downloadConversation"
          >
            {{ $t('CONTACT_PANEL.EXPORT_CONVERSATION') }}
          </button>
          <button v-if="isBafiConversationAssigner" class="button clear" @click="toggleBafiConversationAssigner">
              {{ $t('PLATFORM.BAFI_ASSIGN_CONVERSATION') }}
          </button>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
    <email-transcript-modal
      v-if="showEmailActionsModal"
      :show="showEmailActionsModal"
      :current-chat="currentChat"
      @cancel="toggleEmailActionsModal"
    />
    <bafi-conversation-assigner-modal
            v-if="showBafiConversationAssigner"
            :show="true"
            :current-chat="currentChat"
            @cancel="toggleBafiConversationAssigner"
    />
    <bafi-file-assigner-modal
            v-if="bafiFileAssignerMessage"
            :show="true"
            :current-chat="currentChat"
            :message="bafiFileAssignerMessage"
            @cancel="onCancelPlatformAssignFile"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import fromUnixTime from 'date-fns/fromUnixTime';
import { format } from 'date-fns';
import alertMixin from 'shared/mixins/alertMixin';
import EmailTranscriptModal from './EmailTranscriptModal.vue';
const BafiConversationAssignerModal = () => import(/* webpackChunkName: "platform_views" */ '../online99_platform/BafiConversationAssignerModal')
const BafiFileAssignerModal = () => import(/* webpackChunkName: "platform_views" */ '../online99_platform/BafiFileAssignerModal')
import ResolveAction from '../../buttons/ResolveAction.vue';
import {
  CMD_MUTE_CONVERSATION,
  CMD_SEND_TRANSCRIPT,
  CMD_UNMUTE_CONVERSATION,
} from '../../../routes/dashboard/commands/commandBarBusEvents';
import ModeAction from "../../buttons/ModeAction";
import wootConstants from "../../../constants/globals";
import conversationMixin from "../../../mixins/conversations";
import {BUS_EVENTS} from "../../../../shared/constants/busEvents";
import hasPermissionMixin from 'dashboard/mixins/hasPermission';


export default {
  components: {
    EmailTranscriptModal,
    BafiConversationAssignerModal,
    BafiFileAssignerModal,
    ModeAction,
    ResolveAction,
  },
  mixins: [alertMixin, clickaway, conversationMixin, hasPermissionMixin],
  data() {
    return {
      showConversationActions: false,
      showEmailActionsModal: false,
      showBafiConversationAssigner: false,
      bafiFileAssignerMessage: undefined,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    isBafiConversationAssigner() {
      return online99Constants.platform_AssignConversationFeature;
    },
    isBafiFileAssigner() {
      return online99Constants.platform_SendFileFeature;
    },
    unreadCount() {
      return this.unreadMessagesCount(this.currentChat);
    },
    incomingCount() {
      return this.incomingMessages(this.currentChat).length;
    },
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta.sender.id
      );
    },
    //todo copypaste
    isActive() {
      if (!this.hasInboxPermission('Inbox manual/bot switch')) return false;
      return this.currentChat.status !== wootConstants.STATUS_TYPE.RESOLVED;
    },
  },
  mounted() {
    bus.$on(CMD_MUTE_CONVERSATION, this.mute);
    bus.$on(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$on(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);

    if (this.isBafiFileAssigner) bus.$on(BUS_EVENTS.SET_PLATFORM_ASSIGN_FILE, this.onSetPlatformAssignFile); // d99d

  },
  destroyed() {
    bus.$off(CMD_MUTE_CONVERSATION, this.mute);
    bus.$off(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$off(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);

    bus.$off(BUS_EVENTS.SET_PLATFORM_ASSIGN_FILE, this.onSetPlatformAssignFile); // d99d
  },
  methods: {
    setUnread() {
      this.$store.dispatch('markConversationUnread', this.currentChat);
    },
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'));
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'));
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
      this.hideConversationActions();
    },
    // d99d
    toggleBafiConversationAssigner() {
      this.showBafiConversationAssigner = !this.showBafiConversationAssigner;
      this.hideConversationActions();
    },
    onSetPlatformAssignFile(message) {
      this.bafiFileAssignerMessage = message
    },
    onCancelPlatformAssignFile() {
      this.bafiFileAssignerMessage = undefined
    },
    // .
    messageStamp(time, dateFormat = 'h:mm a') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    getMessage(item) {
      if(item.attachments && (item.attachments.length > 0)) {
        return item.attachments[0].data_url;
      } else {
        return item.content;
      }
    },
    downloadConversation() {
      const csvMessage = [];
      this.hideConversationActions();

      this.currentChat.messages.filter((item,index) => {
        if(item.sender) {
          csvMessage.push({
            id: index,
            contact: this.currentContact.phone_number,
            date: this.messageStamp(item.created_at, 'dd.MM.yyyy'),
            time: this.messageStamp(item.created_at, 'h:mm a'),
            from: item.message_type === 0 ? "client" : "agent",
            from_name: item.message_type === 0 ? this.currentContact.name: item.sender.name,
            to: item.message_type === 0 ? "agent" : "client",
            to_name: item.message_type === 1 ? this.currentContact.name : '',
            message: this.getMessage(item)
          });
        }

        return item
      });

      let csvContent = [
        Object.keys(csvMessage[0]).join(','),
        ...csvMessage.map(item => Object.values(item).join(',')),
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');

      const universalBOM = '\uFEFF';

      const link = document.createElement('a');
      link.setAttribute(
        'href',
        'data:text/csv; charset=utf-8,' +
          encodeURIComponent(universalBOM + csvContent)
      );

      const dateFormat = format(new Date(), 'dd.MM.yyyy');
      const timeFormatHr = format(new Date(), 'HH');
      const timeFormatMm = format(new Date(), 'mm');
      link.setAttribute(
        'download',
        `conversation-${dateFormat}_${timeFormatHr}.${timeFormatMm}.csv`
      );

      link.click();
    },
    toggleConversationActions() {
      this.showConversationActions = !this.showConversationActions;
    },
    hideConversationActions() {
      this.showConversationActions = false;
    },
  },
};
</script>
<style scoped lang="scss">
.more--button {
  @apply items-center flex ml-2 rtl:ml-0 rtl:mr-2;
}

.dropdown-pane {
  @apply -right-2 top-12;

  // d99d
  [dir=rtl] & {
    right: unset;
    left: var(--space-small);;
  }
  // .
}

.icon {
  @apply mr-1 rtl:mr-0 rtl:ml-1 min-w-[1rem];
}
</style>

